import React, { PureComponent, createElement } from 'react';
import each from 'lodash/each'
import { FaQuestion } from 'react-icons/fa'
import BounceIn from 'animate-css-styled-components/lib/Bounce/In'

import Box from '../../components/Box'
import Button from '../../components/Button'
import Text from '../../components/Text'
import PageStart from '../../components/PageStart'
import Radar from '../../components/Radar'
import CircleButton from '../../components/CircleButton';
import Circle from '../../components/Circle';
import Modal from '../../components/Modal';
import withResponsive from '../../hoc/withResponsive'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme'

import Page from '../Page'
import Choices from './Choices'

import gaTrack from '../../utils/gaTrack'


const labels = {
  lunar: {
    main: '農民曆',
    sub: '適合嫁娶的好日子',
  },
  eastern: {
    main: '東方節慶',
    sub: '台灣人最常與另一半度過的東方節慶',
  },
  western: {
    main: '西方節慶',
    sub: '台灣人最常與另一半度過的西方節慶',
  },
  movie: {
    main: '愛情電影',
    sub: '影響對人生以及戀愛關係的想法',
  },
  sun: {
    main: '日照時間',
    sub: '日照長，體內血清素上升，易有自信與好心情',
  },
  humidity: {
    main: '環境溼度',
    sub: '低濕度有助於保持自信狀態',
  },
  bonus: {
    main: '獎金',
    sub: '決定談戀愛資本額的多寡',
  },
  holidays: {
    main: '國定假日',
    sub: '放假心情好，邀約沒煩惱',
  },
};

const meta = { class: 'real' }

class Charts extends PureComponent {
  constructor(props) {
    super(props);
    this.data = props.data.allDataCsv.edges.reduce((all, { node: { month, ...values } }) => {
      each(values, (value, key) => {
        all[key] = all[key] || {}
        all[key][month] = Number(value)
      });
      return all;
    }, {});
  }

  state = {
    filter: false,
    active: 'avg',
    showMethod: false,
  }

  handleToggleFilter = () => {
    const { filter } = this.state
    this.setState({ filter: !filter, active: filter ? 'avg' : 'lunar' })
    gaTrack({ category: '維度', label: filter ? '看平均' : '挑維度' })
  }

  handleSetActive = (e) => {
    const active = e.target.value
    this.setState({ active })
    gaTrack({ category: '維度', label: `選擇維度 - ${labels[active].main}` })
  }

  handleShowMethod = () => {
    gaTrack({ category: '維度', label: '看說明' })
    this.setState({ showMethod: true })
  }

  handleHideMethod = () => this.setState({ showMethod: false })

  render() {
    const { active, showMethod, filter } = this.state;
    const { isMobile } = this.props;

    const choices = (
      <div>
        {filter && (
          <Choices
            active={active}
            labels={labels}
            onSetActive={this.handleSetActive}
            isMobile={isMobile}
          />
        )}
        <Box is={BounceIn} delay="0.5s" mt="2em" textAlign={mobileOrDesktop(filter ? 'left' : 'center', filter ? 'right' : 'left')}>
          {createElement(filter ? Button.minimal : Button, {
            onClick: this.handleToggleFilter,
          }, filter ? '< 看平均' : '挑維度')}
        </Box>
      </div>
    )
    const left = (
      <Box position="relative" pb={mobileOrDesktop('1em', 0)} mt={mobileOrDesktop('0', '3em')}>
        <PageStart
          firstTitle={isMobile ? '預測今年最適合戀愛的月份' : '預測今年'}
          secondTitle={isMobile ? null : '最適合戀愛的月份'}
          descrip="其實有很多因素會催生想戀愛的心情！我們挑出八個關鍵因素作維度，預測今年最容易脫單的時機！"
          subDescrip={!filter && `你可以從${isMobile ? '下方' : '右邊'}大圓欣賞總體的愛情模樣，探索脫單機率大的月份，或是${isMobile ? '' : '從底下'}挑一個感興趣的維度，找到適合自己的戀愛月份！`}
        />
        {!isMobile && choices}
      </Box>
    );
    const right = (
      <Box>
        <Box position="relative" mx={mobileOrDesktop('5%', '10%')}>
          <Radar data={[{ data: this.data[active], meta }]} max={0.9} scales={4} />
          <Box position="absolute" right="0" bottom="10%" width="3em">
            <CircleButton onClick={this.handleShowMethod}>
              <FaQuestion size="1.25em" />
            </CircleButton>
          </Box>
        </Box>
        {isMobile && choices}
      </Box>
    );
    return (
      <div>
        <Page left={left} right={right} />
        <Modal isOpen={showMethod} onRequestClose={this.handleHideMethod}>
          <Box>
            <Circle mx="auto" width="3em" color="white" bg="primary">
              <FaQuestion size="1.25em" />
            </Circle>
            <Text.jin textAlign="center" fontSize="1.5em" mt="1em" mb={mobileOrDesktop('0.5em', '1em')}>圖表怎麼來？</Text.jin>
            <Text>
              為了處理每個維度單位之間不同的問題，我們希望可以把所有值都轉換為0到1之間的值。
            </Text>
            <Text mt="1em">
              於是我們找出每個維度的最大與最小值，並以最大、最小值加減20%的全距做為1跟0，產出現在的圖表。
            </Text>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default withResponsive(Charts);
