import React from 'react';
import { graphql } from 'gatsby';

import PredictPage from '../containers/PredictPage';

export default (props) => (
  <PredictPage {...props} />
)

export const query = graphql`
query RadarData {
  allDataCsv {
    edges {
      node {
        month
        lunar
        eastern
        western
        movie
        sun
        humidity
        bonus
        holidays
        avg
      }
    }
  }
}
`
