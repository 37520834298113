import React from 'react';
import map from 'lodash/map'

import Option from './Option'
import Flex from '../../components/Flex'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Dropdown from '../../components/Dropdown'

const Choices = ({ active, onSetActive, labels, isMobile }) => {
  return isMobile ? (
    <Box pt="1.5em">
      <Dropdown
        onChange={onSetActive}
        options={map(labels, (label, key) => ({ value: key, label: `${label.main}` }))}
        native
      />
      {labels[active] && (
        <Text px="0.5em" my="0.5em" color="variations.gray.7">
          {labels[active].sub}
        </Text>
      )}
    </Box>
  ) : (
    <Flex flexWrap="wrap" pt="1.5em">
      {map(labels, (label, key) => (
        <Option
          key={key}
          name="key"
          value={key}
          onChange={onSetActive}
          label={label.main}
          tooltip={label.sub}
          checked={active === key}
          width={1 / 4}
          px="1em"
          my="0.5em"
          maxWidth="8em"
        />
      ))}
    </Flex>
  );
};

export default Choices;
