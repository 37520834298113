import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system'

import Box from '../../components/Box';
import { customColor } from '../../components/utils/getColor'

const activeBg = css`
  ${customColor('activeBg', 'backgroundColor')};
`;

const activeText = css`
  ${customColor('activeColor', 'color')};
`;

const CircleCheck = styled(Box)`
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .bg, .label {
    transition: all ${themeGet('duration', 250)}ms;
  }

  input:checked ~ .bg {
    ${activeBg}
  }
  input:checked ~ .label {
    ${activeText}
  }
`;

const Tooltip = styled(Box)`
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 2em;
    bottom: -1em;
    color: ${themeGet('colors.primary')};
    border-width: 0.75em;
    border-style: solid;
    border-color: currentColor transparent transparent currentColor;
  }
`;

class Option extends PureComponent {
  state = {
    isHover: false,
  }

  handleEnter = () => this.setState({ isHover: true })

  handleLeave = () => this.setState({ isHover: false })

  render() {
    const { onChange, name, label, checked, value, tooltip, ...props } = this.props;
    const { isHover } = this.state;
    return (
      <Box {...props}>
        <Box position="relative">
          <CircleCheck
            is="label"
            display="block"
            width="100%"
            position="relative"
            pt="100%"
            borderRadius="50%"
            activeBg="primary"
            activeColor="white"
            onMouseEnter={this.handleEnter}
            onMouseLeave={this.handleLeave}
          >
            <input type="radio" name={name} onChange={onChange} checked={checked} value={value} />
            <Box
              is="span"
              display="block"
              className="bg"
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              borderRadius="50%"
              border="1.5px solid"
              borderColor="primary"
            />
            <Box
              is="span"
              display="block"
              className="label"
              position="absolute"
              width="100%"
              left="50%"
              top="50%"
              transform="translate(-50%,-50%)"
              fontFamily="Noto Serif TC"
              px="10%"
              textAlign="center"
            >{label}</Box>
          </CircleCheck>
          {isHover && (
            <Tooltip
              position="absolute"
              bg="primary"
              px="1.5em"
              py="0.75em"
              color="white"
              transform="translateY(-100%)"
              top="0"
              left="33%"
              width={`${tooltip.length + 4}em`}
              maxWidth="12em"
              zOrder={1}
              pointerEvents="none"
              borderRadius="0.5em"
              lineHeight="1.5"
            >
              {tooltip}
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  }
}

export default Option;
